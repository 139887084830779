import {
  UpdateTeamType,
  Tournament,
  Pulje,
  TeamType,
  User,
  KNOCK_OUT_TOURNAMENT_SIZE,
  Match,
  STATUS,
  LocationType,
  Settings,
  MATCH_TYPE,
} from "../types/types";
import { supabase } from "../supabase";

type NewTeamType = {
  teamName: string;
  group: string;
  club?: string;
};

export type dbLocationType = {
  id?: string;
  location: string;
  tournament: string;
  delay: number;
};

export type dbPuljeType = {
  knockoutMatchTime: number;
  groupMatchTime: number;
  id?: string;
  puljeName: string;
  puljeSize: KNOCK_OUT_TOURNAMENT_SIZE;
  tournament?: string;
};

export type dbMatchType = {
  awayTeam?: string | null;
  awayTeamLabel?: string | null;
  awayTeamPoints?: number | null;
  duration?: number;
  endTime?: number | null;
  group?: string | null;
  homeTeam?: string | null;
  homeTeamLabel?: string | null;
  homeTeamPoints?: number | null;
  id?: string;
  knockoutMatchId?: string | null;
  location?: string | null;
  matchType?: MATCH_TYPE;
  pulje?: string | null;
  startTime?: number | null;
  status?: STATUS;
  tournament?: string | null;
};

export const dbCreateUser = async (email: string, password: string) => {
  const { data, error } = await supabase.auth.admin.createUser({ email, password });
  if (data) {
    return data.user?.id;
  } else {
    return error?.message;
  }
};

export const dbCreateGroupMatch = async (match: dbMatchType) => {
  const { data, error } = await supabase.from("matches").insert(match).select("*");
  if (data) {
    return data[0].id;
  } else {
    return error?.message;
  }
};

export const dbCreateTeam = async (team: NewTeamType) => {
  const { data, error } = await supabase.from("teams").insert(team).select("*");
  if (data) {
    return data[0].id;
  } else {
    return error?.message;
  }
};

export const dbCreateGroup = async (group: string, pulje: string) => {
  const { data, error } = await supabase.from("groups").insert({ name: group, pulje }).select("*");
  if (data) {
    return data[0].id;
  } else {
    console.error(error.message);
    throw new Error("Kunne ikke oprette pulje");
  }
};

export const dbCreateLocation = async (location: dbLocationType) => {
  const { data, error } = await supabase.from("locations").insert(location).select("*");
  if (data) {
    return data[0].id;
  } else {
    console.error(error.message);
    throw new Error("Kunne ikke oprette pulje");
  }
};

export const dbCreatePulje = async (pulje: dbPuljeType) => {
  const { data, error } = await supabase.from("puljer").insert(pulje).select("*");
  if (data) {
    return data[0].id;
  } else {
    console.error(error.message);
    throw new Error("Kunne ikke oprette pulje");
  }
};

export const dbUpdateTournament = async (tournament: Tournament) => {
  const { data, error } = await supabase.from("tournaments").update(tournament).eq("id", tournament.id).select();
  if (data) {
    return data[0].id;
  } else {
    console.error(error.message);
    throw new Error("Kunne ikke opdatere turnering");
  }
};

export const dbUpdateSettings = async (settings: Settings) => {
  const { data, error } = await supabase.from("settings").update(settings).eq("id", settings.id).select();
  if (data) {
    return data[0].id;
  } else {
    console.error(error.message);
    throw new Error("Kunne ikke opdatere turnering");
  }
};

export const dbUpdateUserDetails = async (id: string, user: User) => {
  const { data, error } = await supabase.from("users").update(user).eq("id", id).select();
  if (data) {
    return data[0].id;
  } else {
    console.error(error.message);
    throw new Error("Kunne ikke oprette bruger");
  }
};

export const dbUpdatePulje = async (id: string, pulje: dbPuljeType) => {
  const { data, error } = await supabase.from("puljer").update(pulje).eq("id", id).select();
  if (data) {
    return data[0].id;
  } else {
    console.error(error.message);
    throw new Error("Kunne ikke opdatere pulje");
  }
};

export const dbUpdateTeam = async (teamId: string, team: UpdateTeamType) => {
  const { data, error } = await supabase.from("teams").update(team).eq("id", teamId).select();
  if (data) {
    return data[0].id;
  } else {
    console.error(error.message);
    throw new Error("Kunne ikke opdatere holdet");
  }
};

export const dbUpdateGroup = async (pulje: string, groupId: string, groupName: string) => {
  const { data, error } = await supabase
    .from("groups")
    .update({ id: groupId, name: groupName, pulje })
    .eq("id", groupId)
    .select();
  if (data) {
    return data[0].id;
  } else {
    console.error(error.message);
    throw new Error("Kunne ikke opdatere pulje");
  }
};

export const dbUpdateLocation = async (location: dbLocationType) => {
  const { data, error } = await supabase
    .from("locations")
    .update(location)
    .eq("id", location.id ?? "")
    .select();
  if (data) {
    return data[0].id;
  } else {
    console.error(error.message);
    throw new Error("Kunne ikke opdatere pulje");
  }
};

export const dbUpdateGroupMatch = async (matchId: string, field: keyof dbMatchType, value: any) => {
  const { data, error } = await supabase
    .from("matches")
    .update({ [field]: value })
    .eq("id", matchId)
    .select();
  if (data) {
    return data[0].id;
  } else {
    console.error(error.message);
    throw new Error("Kunne ikke opdatere kamp");
  }
};

export const dbUpdateGroupMatch2 = async (matchId: string, match: dbMatchType) => {
  const { data, error } = await supabase.from("matches").update(match).eq("id", matchId).select();
  if (data) {
    return data[0].id;
  } else {
    console.error(error.message);
    throw new Error("Kunne ikke opdatere kamp");
  }
};

export const getAllMatches = async (tournamentId: string) => {
  let { data, error } = await supabase.from("matches").select("*").eq("tournament", tournamentId);

  if (data) {
    return data;
  } else {
    console.error(error?.message);
    throw new Error("Kunne ikke hente gruppe kampe");
  }
};

export const dbDeleteLocation = async (locationId: string) => {
  const { error } = await supabase.from("locations").delete().eq("id", locationId);

  if (error?.message) {
    console.error(error.message);
    throw new Error("Kunne ikke slette banen");
  }
};

export const dbDeleteGroup = async (groupId: string) => {
  const { error } = await supabase.from("groups").delete().eq("id", groupId);

  if (error?.message) {
    console.error(error.message);
    throw new Error("Kunne ikke slette gruppe");
  }
};

export const dbDeleteTeam = async (teamId: string) => {
  const { error } = await supabase.from("teams").delete().eq("id", teamId);

  if (error?.message) {
    console.error(error.message);
    throw new Error("Kunne ikke slette holdet");
  }
};

export const dbDeleteGroupMatch = async (matchId: string) => {
  const { error } = await supabase.from("matches").delete().eq("id", matchId);
};

export const dbDeleteAllMatches = async (tournamentId: string) => {
  const { error } = await supabase.from("matches").delete().eq("tournament", tournamentId);
};

export const dbDeletePulje = async (puljeId: string) => {
  const { error } = await supabase.from("puljer").delete().eq("id", puljeId);

  if (error?.message) {
    console.error(error.message);
    throw new Error("Kunne ikke slette pulje");
  }
};

export const dbUploadGroupMatches = async (matches: dbMatchType[]) => {
  const { data, error } = await supabase.from("matches").upsert(matches).select();
  if (error?.message) {
    console.error(error.message);
    throw new Error("Kunne ikke upload kampe");
  }
};

export const fetchUserDetails = async (uid: string) => {
  const { data, error } = await supabase.from("users").select("*").eq("id", uid);

  if (data) {
    return data[0];
  } else {
    console.error(error);
  }
};

export const sendResetPasswordLink = async (email: string) => {
  try {
    const response = await fetch(
      `https://identitytoolkit.googleapis.com/v1/accounts:sendOobCode?key=${process.env.REACT_APP_API_KEY}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, requestType: "PASSWORD_RESET" }),
      }
    );
    return response.json();
  } catch (error) {
    return error;
  }
};

export const getTournaments = async () => {
  const { data, error } = await supabase.from("tournaments").select("*");

  if (data) {
    return data;
  } else {
    console.error(error);
  }
  return data;
};

export const getSettings = async (settingsId: string) => {
  const { data, error } = await supabase.from("settings").select("*").eq("id", settingsId);

  if (data) {
    return data[0];
  } else {
    console.error(error);
  }
};

export const getActiveTournament = async () => {
  const { data, error } = await supabase.from("tournaments").select("*").eq("isActive", true);

  if (data) {
    return data[0];
  } else {
    console.error(error);
  }
};

export const getTournamentData = async (tournamentId: string) => {
  const response = await Promise.all([
    supabase.from("locations").select("*, tournament: tournaments!inner(*)").eq("tournaments.id", tournamentId),
    supabase.from("puljer").select("*, tournament: tournaments!inner(*)").eq("tournaments.id", tournamentId),
    supabase.from("tournaments").select("*").eq("id", tournamentId),
    supabase.from("clubs").select("*"),
    supabase.from("settings").select("*").eq("tournament", tournamentId),
  ]);

  const settings = (response[4].data as Settings[])[0];
  const clubs = response[3].data ?? [];
  const tournament = (response[2].data ?? [])[0];
  const locations = response[0].data ?? [];
  const puljer = (response[1].data as Pulje[]) ?? [];
  const puljeIds = puljer?.map((obj) => obj.id);

  const response2 = await supabase
    .from("groups")
    .select("*")
    .in("pulje", [...puljeIds]);

  const groups =
    response2.data?.map((group) => {
      return {
        ...group,
        pulje: puljer?.find((pulje) => pulje.id === group.pulje) as Pulje,
      };
    }) ?? [];

  const groupIds = groups?.map((group) => group.id);

  const response3 = await supabase
    .from("teams")
    .select("*")
    .in("group", [...groupIds]);

  const teams =
    response3.data?.map((team) => {
      return {
        ...team,
        club: clubs?.find((club) => club.id === team.club),
        group: groups?.find((group) => group.id === team.group),
      } as TeamType;
    }) ?? [];

  return {
    puljer,
    locations,
    groups,
    teams,
    clubs,
    tournament,
    settings,
  };
};

export const uploadFile = async (filename: string, file: any) => {
  const { data, error } = await supabase.storage.from("club-logos").upload(filename, file, {
    cacheControl: "3600",
    upsert: false,
  });

  if (data) {
    return `${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/${data.fullPath}`;
  } else {
    console.error(error);
  }
};

export const fetchEarlierMatches = async (
  homeTeamClubId: string,
  awayTeamClubId: string,
  tournamentId: string,
  puljeName: string
) => {
  const { data, error } = await supabase
    .from("matches")
    .select(
      `*, homeTeam (*, club: clubs!inner(*)), awayTeam (*, club: clubs!inner(*)), tournament: tournaments!inner(*), pulje: puljer!inner(*)`
    )
    .in("homeTeam.club.id", [homeTeamClubId, awayTeamClubId])
    .in("awayTeam.club.id", [homeTeamClubId, awayTeamClubId])
    .not("homeTeam", "is", null)
    .not("awayTeam", "is", null)
    .neq("tournament.id", tournamentId)
    .eq("status", STATUS.FINISHED)
    .eq("pulje.puljeName", puljeName);

  if (data) {
    return (data as unknown as Match[])
      .sort((a, b) => (b.startTime ?? 0) - (a.startTime ?? 0))
      .filter((match) => match.homeTeam?.club?.id !== match.awayTeam?.club?.id);
  } else {
    console.error(error);
  }
};
