import { getGuid } from "../../utils";

export interface TeamType {
  id: string;
  name: string;
  club: string;
  nationality: "DENMARK" | "NORWAY" | "NETHERLANDS" | "ENGLAND" | "FRANCE" | "SWEDEN";
  potMatches: number[];
  potId: number;
  opponents: TeamType[];
}

export interface Pot {
  potId: number;
  teams: Array<TeamType>;
}

export const pot1 = {
  potId: 1,
  teams: [
    {
      id: getGuid(),
      club: "Dantam IF",
      name: "Dantam IF A",
      nationality: "DENMARK",
      potMatches: [],
      potId: 1,
      opponents: [],
    },
    {
      id: getGuid(),
      club: "Dantam IF",
      name: "Dantam IF B",
      nationality: "DENMARK",
      potMatches: [],
      potId: 1,
      opponents: [],
    },
    {
      id: getGuid(),
      club: "Stovner",
      name: "Stovner Lions",
      nationality: "NORWAY",
      potMatches: [],
      potId: 1,
      opponents: [],
    },
    {
      id: getGuid(),
      club: "Atletico Bergen",
      name: "Atletico Bergen",
      nationality: "NORWAY",
      potMatches: [],
      potId: 1,
      opponents: [],
    },
    {
      id: getGuid(),
      club: "Sønderborg",
      name: "Sønderborg",
      nationality: "DENMARK",
      potMatches: [],
      potId: 1,
      opponents: [],
    },
    {
      id: getGuid(),
      club: "Tiki Taka Bergen",
      name: "Tiki Taka Bergen",
      nationality: "NORWAY",
      potMatches: [],
      potId: 1,
      opponents: [],
    },
    {
      id: getGuid(),
      club: "Nordavind",
      name: "Nordavind",
      nationality: "NORWAY",
      potMatches: [],
      potId: 1,
      opponents: [],
    },
    {
      id: getGuid(),
      club: "Stovner",
      name: "Stovner Giants",
      nationality: "NORWAY",
      potMatches: [],
      potId: 1,
      opponents: [],
    },
  ],
} as Pot;

export const pot2 = {
  potId: 2,
  teams: [
    {
      id: getGuid(),
      club: "Hobro",
      name: "Hobro Black",
      nationality: "DENMARK",
      potMatches: [],
      potId: 2,
      opponents: [],
    },
    {
      id: getGuid(),
      club: "OTSC",
      name: "OTSC A",
      nationality: "DENMARK",
      potMatches: [],
      potId: 2,
      opponents: [],
    },
    {
      id: getGuid(),
      club: "Noreel SK",
      name: "Noreel SK Blue",
      nationality: "NORWAY",
      potMatches: [],
      potId: 2,
      opponents: [],
    },
    {
      id: getGuid(),
      club: "St. Patricks",
      name: "St. Patricks",
      nationality: "FRANCE",
      potMatches: [],
      potId: 2,
      opponents: [],
    },
    {
      id: getGuid(),
      club: "Noreel SK",
      name: "Noreel SK White",
      nationality: "NORWAY",
      potMatches: [],
      potId: 2,
      opponents: [],
    },
    {
      id: getGuid(),
      club: "CS Eelavar",
      name: "CS Eelavar",
      nationality: "FRANCE",
      potMatches: [],
      potId: 2,
      opponents: [],
    },
    {
      id: getGuid(),
      club: "TFC Breda",
      name: "TFC Breda",
      nationality: "NETHERLANDS",
      potMatches: [],
      potId: 2,
      opponents: [],
    },
    {
      id: getGuid(),
      club: "Unique FC",
      name: "Unique FC Red",
      nationality: "NORWAY",
      potMatches: [],
      potId: 2,
      opponents: [],
    },
  ],
} as Pot;

export const pot3 = {
  potId: 3,
  teams: [
    {
      id: getGuid(),
      club: "TFC Fredericia",
      name: "TFC Fredericia White",
      nationality: "DENMARK",
      potMatches: [],
      potId: 3,
      opponents: [],
    },
    {
      id: getGuid(),
      club: "NTSK",
      name: "NTSK",
      nationality: "DENMARK",
      potMatches: [],
      potId: 3,
      opponents: [],
    },
    {
      id: getGuid(),
      club: "Crew74",
      name: "Crew74 Wolves",
      nationality: "DENMARK",
      potMatches: [],
      potId: 3,
      opponents: [],
    },
    {
      id: getGuid(),
      club: "OTSC",
      name: "OTSC B",
      nationality: "DENMARK",
      potMatches: [],
      potId: 3,
      opponents: [],
    },
    {
      id: getGuid(),
      club: "Lewisham United",
      name: "Lewisham United",
      nationality: "ENGLAND",
      potMatches: [],
      potId: 3,
      opponents: [],
    },
    {
      id: getGuid(),
      club: "Aarhus SK",
      name: "Aarhus SK Blue",
      nationality: "DENMARK",
      potMatches: [],
      potId: 3,
      opponents: [],
    },
    {
      id: getGuid(),
      club: "Minnel FC United",
      name: "Minnel FC United",
      nationality: "NORWAY",
      potMatches: [],
      potId: 3,
      opponents: [],
    },
    {
      id: getGuid(),
      club: "Everest SK",
      name: "Everest SK Blue",
      nationality: "NORWAY",
      potMatches: [],
      potId: 3,
      opponents: [],
    },
  ],
} as Pot;

export const pot4 = {
  potId: 4,
  teams: [
    {
      id: getGuid(),
      club: "N.S Paris",
      name: "N.S Paris",
      nationality: "FRANCE",
      potMatches: [],
      potId: 4,
      opponents: [],
    },
    {
      id: getGuid(),
      club: "Hobro",
      name: "Hobro Blue",
      nationality: "DENMARK",
      potMatches: [],
      potId: 4,
      opponents: [],
    },
    {
      id: getGuid(),
      club: "TFC Fredericia",
      name: "TFC Fredericia Blue",
      nationality: "DENMARK",
      potMatches: [],
      potId: 4,
      opponents: [],
    },
    {
      id: getGuid(),
      club: "Odense Super Kings",
      name: "Odense Super Kings",
      nationality: "DENMARK",
      potMatches: [],
      potId: 4,
      opponents: [],
    },
    {
      id: getGuid(),
      club: "Crew74",
      name: "Crew74 Warriors",
      nationality: "DENMARK",
      potMatches: [],
      potId: 4,
      opponents: [],
    },
    {
      id: getGuid(),
      club: "Sweta IF",
      name: "Sweta IF",
      nationality: "SWEDEN",
      potMatches: [],
      potId: 4,
      opponents: [],
    },
    {
      id: getGuid(),
      club: "Aarhus SK",
      name: "Aarhus SK White",
      nationality: "DENMARK",
      potMatches: [],
      potId: 4,
      opponents: [],
    },
    {
      id: getGuid(),
      club: "GTFC",
      name: "GTFC",
      nationality: "DENMARK",
      potMatches: [],
      potId: 4,
      opponents: [],
    },
  ],
} as Pot;
