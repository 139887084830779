import { Draggable } from "react-beautiful-dnd";
import { Match, MATCH_TYPE } from "../../../types/types";
import { formatTime, getKnockoutRoundType } from "../../../utils";
import ballLogo from "../../../assets/icons/ball-logo.png";
import { KnockOutMatches } from "../../../features/matches/matchesSlice";
import KebabMenu from "../../../components/kebabmenu";

interface Props {
  match: Match;
  index: number;
  onDelete: (matchId: string, locationId: string) => void;
  overlap: any;
}

const AdminMatch = (props: Props) => {
  const { index, match, onDelete, overlap } = props;
  return (
    <Draggable key={match.id} draggableId={match.id} index={index}>
      {(provided) => (
        <div
          style={{ position: "relative" }}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <div
            style={{
              border: `1px solid ${match.matchType === MATCH_TYPE.GROUP_MATCH ? "#aaaaff" : "#ffaaaa"}`,
              boxShadow: "rgba(0, 0, 0, 0.04) 0px 5px 22px, rgba(0, 0, 0, 0.03) 0px 0px 0px 0.5px",
            }}
            className="admin-match"
          >
            <div className="column1">
              {formatTime(match.startTime)} - {formatTime(match.endTime)}
            </div>
            <div className="column2">
              <div
                style={{
                  color:
                    (overlap?.matchId1 === match.id || overlap?.matchId2 === match.id) &&
                      match.homeTeam?.id === overlap.overlappingTeamId
                      ? "red"
                      : "black",
                }}
                className="team"
              >
                <img src={match.homeTeam?.club?.logo ?? ballLogo} />
                <p>{match.homeTeam?.teamName ?? match.homeTeamLabel ?? "Pending"}</p>
              </div>
              <div
                style={{
                  color:
                    (overlap?.matchId1 === match.id || overlap?.matchId2 === match.id) &&
                      match.awayTeam?.id === overlap.overlappingTeamId
                      ? "red"
                      : "black",
                }}
                className="team"
              >
                <img src={match.awayTeam?.club?.logo ?? ballLogo} />
                <p>{match.awayTeam?.teamName ?? match.awayTeamLabel ?? "Pending"}</p>
              </div>
            </div>
            <div className="column3">{match.pulje.puljeName}</div>
            <div className="column4">
              {match.group?.name ?? getKnockoutRoundType(match.knockoutMatchId as keyof KnockOutMatches)}
            </div>
            <KebabMenu items={[{ label: "Slet", onClick: () => onDelete(match.id, match.location.id) }]} />
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default AdminMatch;
