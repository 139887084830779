import { useEffect, useState } from "react";
import {
  getAllMatches,
  fetchUserDetails,
  getTournamentData as getStaticTournamentData,
  getActiveTournament,
} from "./api/api";
import "./App.scss";
import { setIsApplicationBootstrapped, setAdmin } from "./features/app/appSlice";
import { setGroupMatches, updateGroupMatch, updateLocationOnAllMatches } from "./features/matches/matchesSlice";
import { useAppDispatch, useAppSelector } from "./hooks";
import { Route, Routes, useNavigate } from "react-router-dom";
import Table from "./screens/table/table";
import Matches from "./screens/matches/matches";
import { setFavoriteTeams, setTeams } from "./features/teams/TeamsSlice";
import { GroupType, Match, LocationType, TeamType, ROLES, Pulje, Tournament } from "./types/types";
import { setGroups } from "./features/groups/GroupsSlice";
import { setPuljer, setSelectedPulje } from "./features/puljer/PuljeSlice";
import LoadingIcon from "./components/loading-icon";
import ErrorPage from "./screens/utils/errorPage";
import { setLocations, updateLocation } from "./features/locations/locationsSlice";
import KnockOut from "./screens/knockout/knockout";
import FrontPage from "./screens/utils/frontPage";
import { removeToast } from "./features/notification/notificationSlice";
import Toast from "./components/notification/Toast";
import { SxProps } from "@mui/material";
import NotificationService from "./components/notification/NotificationService";
import NewAdmin from "./screens/new-admin/new-admin";
import Slideshow from "./screens/slideshow/slideshow";
import { generateGroupsWithTeams, generateTeamsWithGroup, getFavoriteTeamsFromCookies } from "./utils";
import { setTournament } from "./features/tournament/tournamentSlice";
import { signOut, supabase } from "./supabase";
import { setClubs } from "./features/clubs/clubsSlice";
import { RealtimeChannel } from "@supabase/supabase-js";
import MatchDetails from "./screens/match-details/match-details";
import TournamentView from "./screens/admin/tournament/tournament";
import { setSettings } from "./features/settings/settings";
import { Dantam } from "./screens/dantam/dantam";

let supabaseSubscription: RealtimeChannel;

function App() {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const toast = useAppSelector((state) => state.notification?.toast);
  const tournament = useAppSelector((state) => state.tournament.tournament);
  const settings = useAppSelector((state) => state.settings.settings);
  const isAdmin = useAppSelector((state) => state.app.admin);
  const locations = useAppSelector((state) => state.locations.locations);
  const groups = useAppSelector((state) => state.groups.groups);
  const teams = useAppSelector((state) => state.teams.teams);
  const puljer = useAppSelector((state) => state.puljer.puljer);
  const isApplicationBootstrapped = useAppSelector((state) => state.app.isApplicationBootstrapped);

  useEffect(() => {
    if (toast) {
      setShowToast(true);
    }
  }, [toast]);

  const onToastCloseCallback = () => {
    setShowToast(false);
    dispatch(removeToast());
  };

  useEffect(() => {
    supabase.auth.onAuthStateChange((event, session) => {
      if (session?.user.id) {
        fetchUserDetails(session.user.id).then((user) => {
          if (user && user.expiration) {
            if (user.expiration * 1000 < Date.now()) {
              signOut();
              NotificationService.showToast({
                text: `Sorry, but your account has expired.`,
                severity: "error",
                duration: 3000,
              });
              return;
            }
            dispatch(
              setAdmin({
                id: user.id,
                firstname: user.firstname ?? "",
                lastname: user.lastname ?? "",
                email: user.email ?? "",
                expiration: user.expiration ?? 0,
                role: user.role ?? ROLES.EDITOR,
              })
            );
          } else {
            dispatch(setAdmin(undefined));
            signOut();
            NotificationService.showToast({
              text: `Could not fetch user data.`,
              severity: "error",
              duration: 3000,
            });
          }
        });
      } else {
        dispatch(setAdmin(undefined));
      }
    });
  }, []);

  useEffect(() => {
    bootstrap();

    return () => {
      supabaseSubscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    window.addEventListener("focus", reloadMatches);
    return () => window.removeEventListener("focus", reloadMatches);
  }, []);

  const reloadMatches = () => {
    isApplicationBootstrapped && loadMatches(tournament, locations, puljer, groups, teams);
  };

  const bootstrap = async () => {
    setLoading(true);

    const favoriteTeams = getFavoriteTeamsFromCookies();
    favoriteTeams && dispatch(setFavoriteTeams(favoriteTeams));

    const activeTournament = await getActiveTournament();

    if (!activeTournament!?.id) {
      setLoading(false);
      console.error("Fatal error. Could not fetch active tournament");
      return;
    }

    dispatch(setTournament(activeTournament));

    const { clubs, puljer, locations, tournament, teams, groups, settings } = await getStaticTournamentData(
      activeTournament.id
    );
    dispatch(setClubs(clubs));
    dispatch(setPuljer(puljer));
    dispatch(setSelectedPulje(puljer[0]));
    dispatch(setLocations(locations));
    dispatch(setTournament(tournament));
    dispatch(setSettings(settings));

    await loadMatches(activeTournament, locations, puljer, groups, teams);
    dispatch(setIsApplicationBootstrapped(true));
    setLoading(false);
  };

  const loadMatches = async (
    tournament: Tournament,
    locations: Array<LocationType>,
    puljer: Array<Pulje>,
    groups: Array<GroupType>,
    teams: Array<TeamType>
  ) => {
    dispatch(setGroups(generateGroupsWithTeams(groups, teams)));
    dispatch(setTeams(generateTeamsWithGroup(teams, groups)));

    const matches = await getAllMatches(tournament.id);
    const newMatches = matches.map((match: any) => {
      return {
        ...match,
        homeTeam: teams.find((team: TeamType) => team.id === match.homeTeam),
        awayTeam: teams.find((team: TeamType) => team.id === match.awayTeam),
        group: groups.find((group: GroupType) => group.id === match.group),
        location: locations.find((location) => location.id === match.location),
        pulje: puljer.find((pulje) => pulje.id === match.pulje),
        tournament: tournament,
      } as Match;
    });
    dispatch(setGroupMatches(newMatches));

    supabaseSubscription = supabase
      .channel("custom-all-channel")
      .on("postgres_changes", { event: "*", schema: "public", table: "matches" }, (payload: any) => {
        const updatedMatch = {
          ...payload.new,
          homeTeam: teams.find((team: TeamType) => team.id === payload.new.homeTeam),
          awayTeam: teams.find((team: TeamType) => team.id === payload.new.awayTeam),
          group: groups.find((group: GroupType) => group.id === payload.new.group),
          location: locations.find((location) => location.id === payload.new.location),
          pulje: puljer.find((pulje) => pulje.id === payload.new.pulje),
          tournament: tournament,
        } as Match;
        dispatch(updateGroupMatch(updatedMatch));
      })
      .on("postgres_changes", { event: "*", schema: "public", table: "locations" }, (payload: any) => {
        dispatch(updateLocation(payload.new));
        dispatch(updateLocationOnAllMatches({ locationId: payload.new.id, delay: payload.new.delay }));
      })
      .subscribe();
  };

  const mainContent = () => {
    return (
      <Routes>
        <Route path="/" element={<Matches />} />
        <Route path="/matches" element={<Matches />} />
        <Route path="/match-details/:id" element={<MatchDetails />} />
        <Route path="/favorite-matches" element={<Matches />} />
        <Route path="/table" element={<Table />} />
        <Route path="/tournament" element={<TournamentView />} />
        <Route path="/knockout" element={<KnockOut />} />
        <Route path="*" element={<ErrorPage />} />
        <Route path="/admin/*" element={<NewAdmin />} />
        <Route path="/slideshow" element={<Slideshow />} />
        <Route path="/dantam" element={<Dantam />} />
      </Routes>
    );
  };

  return (
    <div className="App">
      {showToast && toast && <Toast toast={toast} onClose={onToastCloseCallback} />}
      <>
        {loading ? (
          <LoadingIcon />
        ) : (
          <>
            {(settings?.isPublic || isAdmin) && tournament?.isActive ? (
              mainContent()
            ) : (
              <Routes>
                <Route path="/" element={<FrontPage />} />
                <Route path="/admin/*" element={<NewAdmin />} />
                <Route path="/dantam" element={<Dantam />} />
                <Route path="*" element={<ErrorPage />} />
              </Routes>
            )}
          </>
        )}
      </>
    </div>
  );
}

export const buttonStyle: SxProps = {
  borderRadius: "10px",
  height: "30px",
  fontSize: "12px",
  fontWeight: "700",
  padding: "10px",
  backgroundColor: "rgb(99, 102, 241)",
  color: "white",

  ":hover": {
    backgroundColor: "rgb(67, 56, 202)",
  },
};

export default App;
