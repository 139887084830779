import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LocationType, Match, TeamType } from "../../types/types";

// Define a type for the slice state
export interface dbMatchUpdate {
  id: string;
  matchUpdate: Match;
}

export interface KnockoutMatchUpdate {
  id: keyof KnockOutMatches;
  pulje: string;
  matchUpdate: Match;
}

export interface KnockoutMatchesUpdate {
  puljeId: string;
  matches: KnockOutMatches;
}
export interface MatchUpdate {
  homeTeamPoints: number;
  awayTeamPoints: number;
  startTime: number;
  endTime: number;
  status: number;
  location: LocationType;
  homeTeam: TeamType;
  awayTeam: TeamType;
}

export interface KnockOutMatches {
  r16_1?: Match;
  r16_2?: Match;
  r16_3?: Match;
  r16_4?: Match;
  r16_5?: Match;
  r16_6?: Match;
  r16_7?: Match;
  r16_8?: Match;
  q_1?: Match;
  q_2?: Match;
  q_3?: Match;
  q_4?: Match;
  s_1?: Match;
  s_2?: Match;
  t?: Match;
  f?: Match;
}
export interface KnockOutTournament {
  [pulje: string]: KnockOutMatches;
}

// Define a type for the slice state
interface MatchesState {
  groupMatches: Array<Match>;
  knockOutTournament: KnockOutTournament;
  editingMatch?: Match;
  lastUpdatedMatch?: Match;
}

// Define the initial state using that type
const initialState: MatchesState = {
  groupMatches: [],
  knockOutTournament: {},
  editingMatch: undefined,
  lastUpdatedMatch: undefined,
};

export const MatchesSlice = createSlice({
  name: "matches",
  initialState,
  reducers: {
    setGroupMatches: (state, action: PayloadAction<Array<Match>>) => {
      state.groupMatches = action.payload;
    },
    updateGroupMatch: (state, action: PayloadAction<Match>) => {
      state.groupMatches = state.groupMatches.map((_match) => {
        if (_match.id === action.payload.id) {
          return {
            ..._match,
            homeTeam: action.payload.homeTeam,
            awayTeam: action.payload.awayTeam,
            homeTeamPoints: action.payload.homeTeamPoints,
            awayTeamPoints: action.payload.awayTeamPoints,
            startTime: action.payload.startTime,
            endTime: action.payload.endTime,
            status: action.payload.status,
            location: action.payload.location,
            duration: action.payload.duration,
            homeTeamLabel: action.payload.homeTeamLabel,
            awayTeamLabel: action.payload.awayTeamLabel,
          };
        } else {
          return _match;
        }
      });
      state.lastUpdatedMatch = action.payload;
    },
    createGroupMatch: (state, action: PayloadAction<Match>) => {
      state.groupMatches = [...state.groupMatches, action.payload];
    },
    deleteGroupMatch: (state, action: PayloadAction<string>) => {
      state.groupMatches = state.groupMatches.filter((match) => match.id !== action.payload);
    },
    setEditingMatch: (state, action: PayloadAction<Match | undefined>) => {
      state.editingMatch = action.payload;
    },
    updateLocationOnAllMatches: (state, action: PayloadAction<{ locationId: string; delay: number }>) => {
      state.groupMatches = state.groupMatches.map((match) => {
        if (match.location.id === action.payload.locationId) {
          return {
            ...match,
            location: {
              ...match.location,
              delay: action.payload.delay,
            },
          };
        } else {
          return match;
        }
      });
    },
  },
});

export const {
  setGroupMatches,
  updateGroupMatch,
  createGroupMatch,
  deleteGroupMatch,
  setEditingMatch,
  updateLocationOnAllMatches,
} = MatchesSlice.actions;

export default MatchesSlice.reducer;
