import { useEffect, useState } from "react";
import { Pot, pot1, pot2, pot3, pot4, TeamType } from "./data";
import { Box, Button, Card, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";

const randomGenerator = (max: number) => {
  return Math.floor(Math.random() * max);
};

const findOpponent = (myTeam: TeamType, searchPot: Pot, existingMatches: Array<Array<TeamType>>) => {
  // Club filtering
  const filteredPot = searchPot.teams.filter((team) => team.id !== myTeam.id).filter((team) => team.club !== myTeam.club);

  // Pot filtering
  let validModstandere = filteredPot.filter(
    (team) => !team.potMatches.includes(searchPot.potId) && !team.potMatches.includes(myTeam.potId)
  );

  // Danske hold kan max møde 2 udenlandske hold???
  if (myTeam.nationality === "DENMARK" && myTeam.opponents.filter((team) => team.nationality !== "DENMARK").length === 2) {
    validModstandere = validModstandere.filter((team) => team.nationality === "DENMARK");
  }

  if (myTeam.nationality !== "DENMARK") {
    validModstandere = validModstandere.filter(
      (team) => team.nationality !== "DENMARK" || team.opponents.filter((team) => team.nationality !== "DENMARK").length < 2
    );
  }

  const randomIndex = randomGenerator(validModstandere.length);

  const opponent = validModstandere[randomIndex];

  return opponent;
};

export const Dantam = () => {
  const [matches, setMatches] = useState<Array<Array<TeamType>>>([]);

  const [pots, setPots] = useState<Pot[]>([]);

  useEffect(() => {
    run();
  }, []);

  useEffect(() => {
    generate();
  }, [pots]);

  const run = () => {
    const original = JSON.parse(JSON.stringify([pot1, pot2, pot3, pot4]));
    setPots(original);
  };

  const generate = () => {
    try {
      const _matches: Array<Array<TeamType>> = [];

      pots.forEach((mainPot) => {
        for (let index = 0; index < mainPot.teams.length; index++) {
          const team = mainPot.teams[index];

          pots.forEach((subPot, index) => {
            if (!team.potMatches.includes(subPot.potId)) {
              const modstander = findOpponent(team, subPot, _matches);
              if (!modstander) throw Error;

              // Swap hver gang for at undgå samme hold er hjemmehold
              if (index % 2) {
                _matches.push([modstander, team]);
              } else {
                _matches.push([team, modstander]);
              }

              team.potMatches.push(subPot.potId);
              team.opponents.push(modstander);
              modstander.potMatches.push(mainPot.potId);
              modstander.opponents.push(team);
            }
          });
        }
      });

      setMatches(_matches);

      runTests(_matches);
    } catch (error) {
      run();
    }
  };

  const runTests = (matches: Array<Array<TeamType>>) => {
    // Check if any danish teams are playing against more than 2 foreign teams
    const matchesWithDanishTeams = matches.filter((match) => match.map((team) => team.nationality).includes("DENMARK"));

    const danishTeams = [...pot1.teams, ...pot2.teams, ...pot3.teams, ...pot4.teams].filter(
      (team) => team.nationality === "DENMARK"
    );

    const result: { teamName: string; result: number }[] = [];

    danishTeams.forEach((team) => {
      result.push({
        teamName: team.name,
        result: matchesWithDanishTeams
          .filter((match) => match.some((_team) => _team.name === team.name))
          .filter((match) => match.some((obj) => obj.nationality !== "DENMARK")).length,
      });
    });

    if (result.every((obj) => obj.result <= 2)) {
      console.log("Ingen danske hold spiller mere end 2 udenlandske kampe");
    } else {
      console.log(
        "Fejl",
        result.sort((a, b) => b.result - a.result)
      );
    }
  };

  return (
    <>
      <Stack sx={{ width: "100%", height: "100%" }}>
        <Card
          sx={{
            display: "flex",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            borderRadius: 0,
            paddingTop: "50px",
            flexWrap: "wrap",
          }}
        >
          {pots.map((pot) => (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                margin: "0px 30px",
                flexWrap: "wrap",
                width: "100%",
                marginBottom: "50px",
                maxWidth: "300px",
              }}
            >
              <Typography paddingBottom={3} variant="h6">
                Pot {pot.potId}
              </Typography>
              <Table style={{ border: "1px solid lightgrey" }}>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Nationality</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pot.teams.map((team, index) => {
                    return (
                      <TableRow hover key={index}>
                        <TableCell>
                          <Typography variant="subtitle2">{team.name}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle2">{team.nationality}</Typography>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          ))}
        </Card>
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
            borderRadius: 0,
            paddingTop: "50px",
            paddingBottom: "50px",
          }}
        >
          <Stack flexDirection="row" justifyContent="space-around" alignItems="center" sx={{ width: "100%" }}>
            <div />
            <Typography paddingBottom={5} variant="h5">
              Matches
            </Typography>
            <Button variant="contained" onClick={run}>
              Regenerate
            </Button>
          </Stack>

          <Box sx={{ width: "80%" }}>
            <Table sx={{ border: "1px solid lightgrey" }}>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Hometeam</TableCell>
                  <TableCell>Awayteam</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {matches.map((match, index) => {
                  return (
                    <TableRow hover key={index}>
                      <TableCell>
                        <Typography variant="subtitle2">{index + 1}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle2">
                          {match[0].name} (P{match[0].potId})
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle2">
                          {match[1].name} (P{match[1].potId})
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
        </Card>
      </Stack>
    </>
  );
};
